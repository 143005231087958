<template>
  <b-modal
    id="info-modal"
    size="xl"
    cancel-title="Anuluj"
    centered
    ok-title="Zamknij"
    ok-only
    title="Komunikat"
  >
  <div v-html="text"></div>
    
  </b-modal>
</template>



<script>
export default {
    props: ['text']
}
</script>

<style scoped>

</style>
