<template>
  <div>
    <b-modal
      title="Dodaj nowy wynajem / wyłączność"
      @ok="sendForm"
      ok-title="Prześlij"
      cancel-title="Anuluj"
      centered
      size="lg"
      id="add-rental"
    >
          <b-alert variant="danger" dismissible>
        Upewnij się, że wprowadziłeś wszystkie dane. Musisz wprowadzić następujące dane aby dodać wynajem: <strong>imię, nazwisko, przebieg start, samochód, opłata tygodniowa, datę rozpoczęcia oraz czas rozpoczęcia wynajmu.</strong>
      </b-alert>
      <b-row class="align-items-center input-row mb-2">
        <b-col cols="6">
          <b-form-group label="Imię">
            <b-form-input v-model="form.first_name"/>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Nazwisko">
            <b-form-input v-model="form.last_name" />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Wybierz samochód">
            <b-form-select v-model="form.rented_car">
              <b-form-select-option :value="car.reg" v-for="car in returnCars">{{car.reg}}</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Opłata tygodniowa">
            <b-form-input type="number" v-model="form.weekly_price" />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Przebieg start">
            <b-form-input type="number" v-model="form.mileage_start" />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Przebieg koniec">
            <b-form-input type="number" disabled v-model="form.mileage_end" />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Start wynajmu">
            <b-form-datepicker  start-weekday="1" v-model="form.start_date"/>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Koniec wynajmu">
            <b-form-datepicker  start-weekday="1" disabled v-model="form.end_date"/>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Godzina rozpoczęcia" v-model="form.start_time">
            <b-form-timepicker v-model="form.start_time" locale="pl" placeholder="Wybierz godzinę" />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Godzina zakończenia" >
            <b-form-timepicker v-model="form.end_time" locale="pl" placeholder="Wybierz godzinę" disabled />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Numer telefonu" >
            <b-form-input v-model="form.phone_number" placeholder="Numer telefonu"  />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Typ" >
            <b-form-select v-model="form.type" :options="options" placeholder="Wyłączność lub Wynajem" />
          </b-form-group>
        </b-col>
      </b-row>
     </b-row>
    </b-modal>
  </div>
</template>

<script>
export default {

  data() {
    return {
      fullName: null,
      phone: null,
      formSent: false,
      options: [{value: "Wyłączność", text: "Wyłączność"},{value: "Wynajem", text: "Wynajem"}],
      form: {
        first_name: null,
        last_name: null,
        rented_car: null,
        weekly_price: null,
        mileage_start: null,
        mileage_end: null,
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        type: null,
        phone_number: null,
      },
    };
  },
  async created () {
    await this.getCars();
  },
  methods: {
    async sendForm() {
      if (
        this.form.first_name &&
        this.form.last_name &&
        this.form.rented_car &&
        this.form.weekly_price &&
        this.form.mileage_start &&
        this.form.start_date &&
        this.form.start_time
      ) {
        this.$store.dispatch("addRental", this.form).then((res) => {
          this.$bvModal.msgBoxOk("Wynajem dodany został pomyślnie.", {
            headerBgVariant: "primary",
            title: "Dodano pomyślnie",
            headerTextVariant: "light",
            centered: true,
          });
        });
        this.$emit('sent')
      } else {
        this.notAllData = true;
      }
    },
    async getCars() {
      await this.$store.dispatch("getCars");
    },
 },
 computed : {
      returnCars() {
      const cars = this.$store.getters["returnCars"];
      return !this.isLoading && cars;
    },
 }
}
</script>

<style>
.row .col .singleday {
  border: 1px solid black;
  text-align: center;
  height: 100px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.selected {
  background-color: rgb(224, 224, 136);
}
</style>
