<template>
  <b-modal
    centered
    id="add-grounded"
    ok-title="Zapisz"
    @ok="addGrounded"
    cancel-title="Anuluj"
    size="lg"
    title="Dodaj wyłączenie dla pojazdu"
  >
    <h3>Start wyłączenia</h3>
    <b-form-group label="Wybierz samochód">
      <b-form-select v-model="form.car">
        <b-form-select-option :value="car" v-for="car in returnCars">{{
          car.reg
        }}</b-form-select-option>
      </b-form-select>
    </b-form-group>
    <b-form-group
      v-if="form.car"
      label="Wybierz dzień, od którego auto jest wyłączone"
    >
      <b-form-datepicker
        v-model="form.dayStart"
        placeholder="Nie wybrano daty"
      />
    </b-form-group>
    <b-form-group
      v-if="form.car"
      label="Wybierz zmianę, od której auto jest niedostępne"
    >
      <b-form-select v-model="form.shiftStart" :options="['Dzień', 'Noc']">
      </b-form-select>
    </b-form-group>
    <h3 v-if="form.car">Koniec wyłączenia</h3>
    <b-form-group
      v-if="form.car"
      label="Wybierz dzień, w którym kończy się wyłączenie pojazdu"
    >
      <b-form-datepicker v-model="form.dayEnd" placeholder="Nie wybrano daty" />
    </b-form-group>
    <b-form-group
      v-if="form.car"
      label="Wybierz zmianę, do której auto jest wyłączone"
    >
      <b-form-select v-model="form.shiftEnd" :options="['Dzień', 'Noc']">
      </b-form-select>
    </b-form-group>
    <b-form-group v-if="form.car" label="Ukryj auto w kalendarzu publicznym">
      <b-form-checkbox
        size="lg"
        v-model="form.notOnStation"
        name="check-button"
        switch
      />
    </b-form-group>
  </b-modal>
</template>    

<script>
export default {
  data() {
    return {
      selectedDriver: null,
      notValid: false,
      isLoading: false,
      edit: null,
      sortBy: "serviceDate",
      editServiceId: null,
      form: {
        dayStart: null,
        dayEnd: null,
        shiftStart: null,
        shiftEnd: null,
        notOnStation: null,
        car: null,
      },
    };
  },
  async created() {
    await this.getCars();
  },
  methods: {
    async getCars() {
      await this.$store.dispatch("getCars");
    },
    async addGrounded() {
      const carReg = this.form.car.reg;
      const carId = this.form.car.id;
      const week = this.serviceWeek;
      if (
        this.form.shiftStart &&
        this.form.shiftEnd &&
        this.form.dayStart &&
        this.form.dayEnd
      ) {
        await this.$store.dispatch("addGrounded", {
          ...this.form,
          carReg,
          carId,
          week,
        });
      }
    this.$emit('sent')
    },
  },
  computed: {
    returnCars() {
      const cars = this.$store.getters["returnCars"];
      return !this.isLoading && cars;
    },
    serviceWeek() {
      if (this.form.dayStart) {
        return this.$dayjs(this.form.dayStart).week();
      }
      return null;
    },
  },
};
</script>