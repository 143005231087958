<template>
  <b-sidebar
    id="sidebar-admin"
    aria-labelledby="sidebar-admin-title"
    sidebar-class="admin-sidebar"
    left
    no-header
    bg-variant="primary"
    text-variant="light"
    backdrop
    shadow
  >
    <template #default>
      <div class="p-3">
        <h3 id="sidebar-admin-title">
          <b-icon class="admin-logo" height="2rem" width="2rem" icon="people" />
          AutoGrafik
        </h3>
        <div class="admin-menu">
          <p class="divider" />

          <router-link to="/admin" class="display"
            ><b-icon class="admin-icon" icon="card-checklist" />
            Dashboard</router-link
          >
          <p class="divider" />
          <div class="menu-section">
            <p class="section-title">grafik</p>
            <router-link to="/admin/edycja-grafiku"
              ><b-icon
                class="admin-icon"
                icon="arrow-left-right
"
              />
              Edycja
            </router-link>
            <router-link to="/admin/podglad-grafiku"
              ><b-icon
                class="admin-icon"
                icon="clipboard-check
"
              />
              Harmonogramy</router-link
            >
            <router-link to="/admin/grafiki-pracy"
              ><b-icon
                class="admin-icon"
                icon="calendar-check
"
              />
              Grafiki pracy</router-link
            >
            <router-link to="/admin/bonusy"
              ><b-icon
                class="admin-icon"
                icon="cash-stack"
              />
              Bonusy</router-link
            >
            <router-link to="/admin/raporty"
              ><b-icon
                class="admin-icon"
                icon="bar-chart-fill
"
              />
              Raporty</router-link
            >
            <router-link to="/admin/wyniki"
              ><b-icon
                class="admin-icon"
                icon="award
"
              />
              Wyniki</router-link
            >
            <router-link to="/admin/pracownicy/lista-pracownikow"
              ><b-icon
                class="admin-icon"
                icon="emoji-sunglasses"
              />
              Aktywni kierowcy</router-link
            >
          </div>
          <p class="divider" />
          <div class="menu-section">
            <p class="section-title">Ustawienia</p>
            <router-link to="/admin/miesiac"
              ><b-icon class="admin-icon" icon="calendar2-range" />
              Miesiąc</router-link
            >
          </div>
          <!-- <div class="menu-section" v-if="returnCurrentUser.admin">
            <p class="section-title">Wyniki</p>
            <router-link to="/admin/wyniki-dzienne"
              ><b-icon class="admin-icon" icon="calendar2-range" />
              Wyniki dzienne</router-link
            >
            <router-link to="/admin/wyniki-tygodniowe"
              ><b-icon class="admin-icon" icon="calendar2-range" />
              Wyniki tygodniowe</router-link
            >
          </div> -->
          <p class="divider" />
          <div class="menu-section">
            <p class="section-title">Flota</p>
            <router-link to="/admin/samochody"
              ><b-icon
                class="admin-icon
"
                icon="truck"
              />
              Samochody</router-link
            >
            <router-link to="/admin/wylacznosc"
              ><b-icon
                class="admin-icon
"
                icon="person-circle"
              />
              Wyłączność</router-link
            >
          </div>
          <p class="divider" />

          <div class="menu-section">
            <p class="section-title">Wsparcie techniczne</p>
            <router-link to="/pomoc"
              ><b-icon
                class="admin-icon
"
                icon="chat-fill"
              />
              Pomoc</router-link
            >
          </div>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
export default {
  data() {
    return {
      sidebar: false,
    };
  },
  methods: {},
  computed: {

    returnCurrentUser(){
      return !this.isLoading && this.$store.state.user
    }
  },
};
</script>

<style scoped>
#sidebar-admin {
  text-align: center;
  z-index: 9999;
}
h3 {
  text-align: center;
}
p {
  margin-bottom: 10px;
}
.admin-subtitle {
  font-size: 14px;
  letter-spacing: 4px;
}
.admin-menu {
  display: flex;
  flex-direction: column;
  margin: 0 22px;
}
.menu-section .section-title {
  font-size: 12px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.4);
}
.menu-section {
  display: flex;
  flex-direction: column;
}
.admin-menu .nav-item,
.menu-section a {
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
  margin: 5px 0;
  font-size: 1.2em;
}
.admin-menu .nav-item:hover,
.menu-section a:hover {
  color: rgba(255, 255, 255, 1);
}
.nav-item.router-link-exact-active {
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
}
.divider {
  margin: 0 1rem 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}
</style>
