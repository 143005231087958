<template>
  <b-tabs id="tab-container" pills content-class="mt-3" align="center">
    <b-tab title="Ustawienia miesiąca" active>
      <b-row class="align-items-center input-row">
        <b-col md="4">
          <h5>Tydzień rozliczeniowy</h5>
          <p class="title">
            Zmiana tygodnia zmieni również wyświetlany tydzień oraz limity.
          </p>
        </b-col>
        <b-col class="input-container">
          <b-form-select
            class="form-input"
            :value="getSettings.week"
            :options="getWeeks"
            size="lg"
            @change="changeMonth"
          ></b-form-select>
        </b-col>
      </b-row>
      <b-row class="align-items-center input-row">
        <b-col md="4">
          <h5>Administracja - tydzień</h5>
          <p class="title">
            Zmiana tygodnia zmieni wyświetlany tydzień w podglądzie panelu
            administracyjnego.
          </p>
        </b-col>
        <b-col class="input-container">
          <b-form-select
            class="form-input"
            :value="getSettings.adminWeek"
            :options="getWeeks"
            size="lg"
            @change="changeAdminMonth"
          ></b-form-select>
        </b-col>
      </b-row>
      <b-row class="align-items-center input-row">
        <b-col md="4">
          <h5>Domyślna dostępność</h5>
          <p class="title">Zmiana domyślnej dostępności osób na zmianie.</p>
        </b-col>
        <b-col class="input-container">
          <b-form-spinbutton
            @change="changeDefault"
            :value="getSettings.defaultLimit"
            min="1"
            max="25"
          ></b-form-spinbutton>
        </b-col>
      </b-row>
      <b-row class="align-items-center input-row">
        <b-col md="4">
          <h5>Podgląd grafiku</h5>
          <p class="title">
            Wyłącz / włącz widoczność wygenerowanego grafiku dla kierowców i
            koordynatorów.
          </p>
        </b-col>
        <b-col class="input-container">
          <b-form-checkbox
            v-model="getSettings.showSchedule"
            @change="toggleView"
            switch
            size="lg"
          ></b-form-checkbox>
        </b-col>
      </b-row>
      <b-row
        class="align-items-center input-row"
        v-if="getSettings.showSchedule"
      >
        <b-col md="4">
          <h5>Podgląd - tydzień</h5>
          <p class="title">
            Zmiana tygodnia - który wyświetlany jest koordynatorom oraz
            pracownikom.
          </p>
        </b-col>
        <b-col class="input-container">
          <b-form-select
            class="form-input"
            :value="getSettings.showWeek"
            :options="getWeeks"
            size="lg"
            @change="changeShowWeek"
          ></b-form-select>
        </b-col>
      </b-row>
      <b-row
        class="align-items-center input-row"
        v-if="getSettings.showSchedule"
      >
        <b-col md="4">
          <h5>PIN dostępowy</h5>
          <p class="title">
            Umożliwia zmianę pinu dostępowego - pin składa się z 4 cyfr,
            generowany jest automatycznie.
          </p>
        </b-col>
        <b-col class="input-container">
          <b-input-group>
            <b-form-input
              class="form-input"
              type="number"
              :value="getSettings.pin"
              size="lg"
              disabled
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="primary" @click="generetePin"
                >Generuj</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </b-tab>
    <b-tab title="Limity dzienne">
      <b-row class="align-items-center justify-content-center input-row">
        <b-col>
          <h5>Nowe ograniczenie</h5>
          <p class="title">
            Dodaj ograniczenie dotyczące dostępności pojazdów. Możliwe jest
            dodanie ograniczenia na cały dzień, jak i na wybraną zmianę.
          </p>
        </b-col>
        <b-col lg="6" class="input-container">
          <b-button-group>
            <b-button
              variant="danger"
              @click="limitsSetup('both')"
              v-b-modal.limitchanger
              >Dzień / Noc</b-button
            >
            <b-button
              variant="dark"
              @click="limitsSetup('day')"
              v-b-modal.limitchanger
              >Cały dzień</b-button
            >
          </b-button-group>
        </b-col>
      </b-row>
      <b-row class="align-items-center justify-content-center input-row">
        <b-col>
          <h5>Aktywne ograniczenia</h5>
          <p class="title">
            Ograniczenia aktualnie aktywne na
            <strong>wszystkie miesiące</strong>. Możesz dodać tylko i wyłącznie
            dwa ograniczenia na jeden dzień.
          </p>
        </b-col>
        <b-col lg="6">
          <b-list-group>
            <transition-group name="limits" tag="div" class="singlelimit">
              <div key="item">
                <b-list-group-item
                  class="d-flex justify-content-between align-items-center"
                  id="display-box"
                  v-for="dayLimits in getDetails.activeLimits"
                  :key="dayLimits.id"
                >
                  {{ dayLimits.dayNumber }}.{{ dayLimits.month }}.{{
                    dayLimits.year
                  }}
                  <div>
                    <span class="badges">
                      <b-badge
                        variant="danger"
                        class="badge"
                        v-if="dayLimits.day"
                        >Dzień: {{ dayLimits.day }}</b-badge
                      >
                      <b-badge
                        variant="primary"
                        class="badge"
                        v-if="dayLimits.night"
                        >Noc: {{ dayLimits.night }}</b-badge
                      >
                    </span>
                  </div>
                  <b-icon
                    icon="x-circle-fill"
                    variant="danger"
                    class="delete"
                    @click="
                      removeLimit(
                        dayLimits.id,
                        `${dayLimits.dayNumber}.${dayLimits.month}.${dayLimits.year}`
                      )
                    "
                  />
                </b-list-group-item>
              </div>
            </transition-group>
          </b-list-group>
        </b-col>
      </b-row>
    </b-tab>
    <b-tab title="Ustawienia powiadomień">
      <b-row class="align-items-center input-row">
        <b-col md="4">
          <h5>Włącz popup</h5>
          <p class="title">Włącz wyskakujące okno w dashboardzie.</p>
        </b-col>
        <b-col class="input-container">
          <b-form-checkbox
            v-model="getSettings.showPopup"
            switch
            @change="togglePopup"
            size="lg"
          ></b-form-checkbox>
        </b-col>
      </b-row>
      <b-row class="align-items-center input-row">
        <b-col md="4">
          <h5>Komunikat dla popup</h5>
          <p class="title">Wprowadź komunikat dla wyskakującego okna. Tekst zapisywany jest automatycznie - po wprowadzeniu odśwież stronę i upewnij się, że wyświetla się właściwy komunikat.</p>
        </b-col>
        <b-col class="input-container">
          <b-textarea
            v-model="getSettings.popupText"
            @change="updatePopupText"
            size="lg"
            rows="5"
          ></b-textarea>
        </b-col>
      </b-row>
    </b-tab>
    <b-modal
      id="limitchanger"
      header-bg-variant="primary"
      cancel-title="Powrót"
      ok-title="Dodaj"
      header-text-variant="light"
      @ok="submitLimit"
      :title="returnTitle"
    >
      <div class="form-group">
        <label for="daypicker">Wybierz dzień do zmiany limitu</label>
        <b-form-datepicker
          id="daypicker"
          placeholder="Naciśnij aby wybrać dzień"
          locale="pl"
          v-model="form.date"
        ></b-form-datepicker>
      </div>
      <div class="form-group">
        <label for="carsnum">Liczba pojazdów</label>
        <b-form-input
          v-model="form.carsNum"
          id="carsnum"
          number
          type="number"
          min="1"
        />
      </div>
      <b-form-group label="Wybierz zmianę" v-if="shiftLimitMode === `both`">
        <b-form-radio-group
          id="btn-radios-2"
          v-model="form.shiftOption"
          :options="shifts"
          button-variant="primary"
          size="lg"
          buttons
        />
      </b-form-group>
      <b-form-group label="Komentarz">
        <b-form-textarea v-model="form.info" rows="3" />
      </b-form-group>
    </b-modal>
  </b-tabs>
</template>
<script>
import AdminSidebar from "../components/AdminSidebar.vue";
export default {
  components: { AdminSidebar },
  data() {
    return {
      selectedMonth: null,
      selectedYear: null,
      shiftLimitMode: null,
      form: { date: null, carsNum: null, info: null, shiftOption: null },
      isLoading: false,
      shifts: [
        { text: "Dzień", value: "Dzień" },
        { text: "Noc", value: "Noc" },
      ],
    };
  },
  created() {
    this.fetchSettings();
  },
  methods: {
    changeMonth(value) {
      this.$store.dispatch("changeWeek", { week: value });
    },
    changeAdminMonth(value) {
      this.$store.dispatch("adminYearMonth", { value: value, mode: "week" });
    },
    changeShowWeek(value) {
      this.$store.dispatch("changeShowWeek", value);
    },
    toggleView(value) {
      this.$store.dispatch("toogleScheduleView", value);
    },
    togglePopup(value) {
      this.$store.dispatch("togglePopup", value);
    },
    updatePopupText(value) {
      this.$store.dispatch("updatePopupText", value);
    },
    async removeLimit(itemId, date) {
      await this.$store
        .dispatch("removeLimit", { id: itemId })
        .then((res) =>
          this.$bvToast.toast(
            `Limit na dzień ${date} został usunięty pomyślnie.`,
            {
              title: "Sukces!",
              solid: true,
              appendToast: true,
            }
          )
        )
        .catch((err) => {
          this.$bvModal.msgBoxOk(
            "Nie udało się usunąć limitu - spróbuj ponownie później.",
            {
              okVariant: "danger",
              title: "Wystąpił błąd",
              centered: true,
            }
          );
        });
    },
    changeYear(value) {
      this.$store.dispatch("changeYear", value);
    },
    changeAdminYear(value) {
      this.$store.dispatch("adminYearMonth", { value: value, mode: "year" });
    },
    changeDefault(value) {
      this.$store.dispatch("changeDefaultLimit", value);
    },

    async fetchSettings() {
      this.isLoading = true;
      await this.$store.dispatch("fetchASettings");
      this.isLoading = false;
    },
    limitsSetup(value) {
      this.shiftLimitMode = value;
    },
    generetePin() {
      const newPin = [];
      for (let i = 0; i < 4; i++) {
        newPin.push(Math.floor(Math.random() * (9 - 1 + 1) + 1));
      }
      this.$store.dispatch("changePin", newPin.join(""));
    },
    submitLimit() {
      const date = this.form.date.split("-");
      let year = parseInt(date[0]);
      let month = parseInt(date[1]);
      let day = parseInt(date[2]);
      if (this.form.shiftOption && this.form.shiftOption === "Dzień") {
        this.$store
          .dispatch("addLimits", {
            dayNumber: day,
            month: month,
            year: year,
            day: this.form.carsNum,
            info: this.form.info,
            active: true,
          })
          .then((res) =>
            this.$bvToast.toast("Limit na dzień został dodany pomyślnie.", {
              title: "Sukces!",
              solid: true,
              appendToast: true,
            })
          )
          .catch((err) => {
            this.$bvModal.msgBoxOk(err, {
              okVariant: "danger",
              title: "Wystąpił błąd",
              centered: true,
            });
          });
        this.form.shiftOption = null;
      }
      if (this.form.shiftOption && this.form.shiftOption === "Noc") {
        this.$store
          .dispatch("addLimits", {
            dayNumber: day,
            month: month,
            year: year,
            night: this.form.carsNum,
            info: this.form.info,
            active: true,
          })
          .then((res) =>
            this.$bvToast.toast("Limit na noc został dodany pomyślnie.", {
              title: "Sukces!",
              solid: true,
              appendToast: true,
            })
          )
          .catch((err) => {
            this.$bvModal.msgBoxOk(err, {
              okVariant: "danger",
              title: "Wystąpił błąd",
              centered: true,
            });
          });
        this.form.shiftOption = null;
      }
      if (this.shiftLimitMode === "day") {
        this.$store
          .dispatch("addLimits", {
            dayNumber: day,
            month: month,
            year: year,
            day: this.form.carsNum,
            night: this.form.carsNum,
            info: this.form.info,
            active: true,
          })
          .then((res) =>
            this.$bvToast.toast(
              "Limit na cały dzień został dodany pomyślnie.",
              {
                title: "Sukces!",
                solid: true,
                appendToast: true,
              }
            )
          )
          .catch((err) => {
            this.$bvModal.msgBoxOk(err, {
              okVariant: "danger",
              title: "Wystąpił błąd",
              centered: true,
            });
          });
      }
    },
  },
  computed: {
    getTwoYears() {
      let dateNow = new Date();
      return [
        { value: dateNow.getUTCFullYear(), text: dateNow.getUTCFullYear() },
        {
          value: dateNow.getUTCFullYear() + 1,
          text: dateNow.getUTCFullYear() + 1,
        },
      ];
    },
    getSettings() {
      return this.$store.getters["getSettings"];
    },
    getDetails() {
      return this.$store.getters["getDetails"];
    },
    returnTitle() {
      if (this.shiftLimitMode) {
        if (this.shiftLimitMode === "both") {
          return "Dodaj nowy limit: pojedyncza zmiana";
        } else {
          return "Dodaj nowy limit: cały dzień";
        }
      }
    },
    getWeeks() {
      const weeks = [];
      const nextWeek = this.$dayjs().weekday(0).week() + 1;
      const today = this.$dayjs().weekday(0).week();
      for (let i = -3; i < 3; i++) {
        weeks.push({
          value:
            this.$dayjs()
              .week(today + i)
              .weekday(0)
              .week() +
              1 ===
            53
              ? 1
              : this.$dayjs()
                  .week(today + i)
                  .weekday(0)
                  .week() + 1,
          text: `${
            this.$dayjs()
              .week(nextWeek + i)
              .weekday(0).$D
          }.${
            this.$dayjs()
              .week(nextWeek + i)
              .weekday(0).$M + 1
          }.${this.$dayjs()
            .week(nextWeek + i)
            .weekday(0)
            .year()} - ${
            this.$dayjs()
              .week(nextWeek + i)
              .weekday(6).$D
          }.${
            this.$dayjs()
              .week(nextWeek + i)
              .weekday(6).$M + 1
          }.${this.$dayjs()
            .week(nextWeek + i)
            .weekday(6)
            .year()}`,
        });
      }
      return weeks;
    },
  },
};
</script>

<style scoped>
#tab-container {
  width: 80%;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
}
#tab-container .nav.nav-pills .nav-item a {
  color: black !important;
}
.limits-enter-active,
.limits-leave-active {
  transition: all 1s;
}
.limits-enter,
.limits-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.title {
  font-weight: 300;
}
.form-input {
  width: 100%;
}
.input-row {
  margin-top: 2em;
}
.input-container {
  text-align: center;
}

.close {
  background-color: black;
  padding: 20px;
}
.delete {
  margin-left: 20px;
  font-size: 20px;
  cursor: pointer;
}
.badge {
  padding: 5px;
  margin: 0px 5px;
}
.form-input {
  text-align: center;
}
@media (max-width: 500px) {
  .badges {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}
</style>
